import { BasicRoute } from './BasicRoute';
import { actions } from "../utilities/MENU_ACTIONS.js";
import { ParentRoute } from "../routes/ParentRoute";

export default [
    new ParentRoute('/businesswallet', 'Business Wallet', () => import('../components/templates/ParentDirectory.vue')),
    new BasicRoute('/businesswallet/aplicaciones/terminalvirtual','bwterminalvirtual',() => import('../pages/Custom/BusinessWallet/VirtualTerminal.vue'),'Business Wallet', 'Terminal Virtual'),
    new BasicRoute('/businesswallet/aplicaciones/link','bwlinkpago',() => import('../pages/Custom/BusinessWallet/LinkPago.vue'),'Business Wallet', 'Link de Pago'),
    new BasicRoute('/businesswallet/aplicaciones/transacciones','bwtransacciones',() => import('../pages/Custom/BusinessWallet/Transactions.vue'),'Business Wallet', 'Transacciones'),
    new BasicRoute('/businesswallet/aplicaciones/configuracion','bwconfiguracion',() => import('../pages/Custom/BusinessWallet/Configuration.vue'),'Business Wallet', 'Configuracion'),
    new BasicRoute('/businesswallet/aplicaciones/adminlinkpago','bwadminlinkpago',() => import('../pages/Custom/BusinessWallet/AdminLinkPago.vue'),'Business Wallet', 'Administrador Link Pago'),
    new BasicRoute('/businesswallet/catalogos/producto','bwproducto',() => import('../pages/Custom/BusinessWallet/Catalogos/Product.vue'),'Business Wallet', 'Producto', [actions.save, actions.refresh]),
    new BasicRoute('/businesswallet/catalogos/terminalesfisicas','bwterminalesfisicas',() => import('../pages/Custom/BusinessWallet/Devices.vue'),'Business Wallet', 'Terminales Fisicas'),
    new BasicRoute('/businesswallet/aplicaciones/terminalvirtuales','bwdatosdepago',() => import('../pages/Custom/BusinessWallet/LinkCardInformation.vue'),'Business Wallet', 'Datos de pago'),
    new BasicRoute('/businesswallet/aplicaciones/terminalvirtuallite','bwterminalvirtuallite',() => import('../pages/Custom/BusinessWallet/VirtualTerminalLite.vue'),'Business Wallet', 'Terminal Virtual Lite'),
    new BasicRoute('/businesswallet/aplicaciones/adminfactura','bwtootleinvoice',() => import('../pages/Custom/BusinessWallet/Admin/Invoice.vue'),'Business Wallet', 'Facturas'),
    new BasicRoute('/businesswallet/aplicaciones/universetransactions','bwuniversaltransaction',() => import('../pages/Custom/BusinessWallet/UniverseTransation.vue'),'Business Wallet', 'Transacciones Universales'),
    new BasicRoute('/businesswallet/aplicaciones/administradortoken','administradortoken',() => import('../pages/Custom/BusinessWallet/AdminToken.vue'),'Business Wallet', 'Administrador de token '),
    new BasicRoute('/businesswallet/aplicaciones/administradortokencliente','administradortokencliente',() => import('../pages/Custom/BusinessWallet/AdminTokenCliente.vue'),'Business Wallet', 'Administrador de token-cliente'),
    new BasicRoute('/businesswallet/reportes/reportesbw','reportesbw',() => import('../pages/Custom/BusinessWallet/Reportes/ReportesBW.vue'),'Business Wallet', 'Reportes'),
    new BasicRoute('/cfdi40/catalogos/cliente','cfdi40cliente',() => import('../pages/Custom/BusinessWallet/Catalogos/Customer.vue'),'Comercial', 'Cliente'),
    new BasicRoute('/Custom/BusinessWallet/Catalogo/bw_bank_holiday','Dias Festivos',() => import('../pages/Custom/BusinessWallet/BankHoliday.vue'),'BusinessWallet', 'Dias Festivos') ,
    new BasicRoute('/businesswallet/reportes/reportesConfiguration','reportesConfigurationbw',() => import('../pages/Custom/BusinessWallet/Reportes/ReportConfiguration.vue'),'Business Wallet', 'Reporte de Configuracion General'),
]

